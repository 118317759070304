{
  "delivery": "Доставка",
  "crusher": "Крашер",
  "glubinaKopaniya": "Глубина копания",
  "moving-out": "Вывоз",
  "assembling": "Монтаж",
  "disassembling": "Демонтаж",
  "idle": "Простой",
  "mileage": "Пробег",
  "oversizeFreightForwarding": "Сопровождение негабаритного груза",
  "security": "Охрана",
  "augerDrilling": "Бурение шнеком",
  "siltdrawerDrain": "Слив илососа",
  "other": "Другое",
  "rent": "Услуги техники",
  "shalanda": "Шаланда (длинномер)",
  "samosvalnyi-polupricep": "Самосвальный полуприцеп",
  "gruzo-passazhirskij-avtotransport": "Грузо-пассажирский автотранспорт",
  "bara": "Бара (ЭТЦ)",
  "betonomeshalka": "Миксер",
  "benzovoz": "Бензовоз",
  "bashenny_kran": "Башенный кран",
  "avtocisterny": "Автоцистерны (включая автопоезда)",
  "agregat-cementirovochnyj": "Агрегат цементировочный",
  "avtokran": "Автокран",
  "manipulyator": "Манипулятор",
  "tral": "Трал (низкорамный прицеп)",
  "ekskavator-pogruzchik": "Экскаватор-погрузчик",
  "gusenichnyi-ekskavator": "Экскаватор гусеничный",
  "kolesnyi-ekskavator": "Экскаватор колесный",
  "mini-ekskavator": "Мини экскаватор",
  "frontalnyi-pogruzchik": "Фронтальный погрузчик",
  "minipogruzchik": "Минипогрузчик",
  "teleskopicheskyi-pogruzchik": "Телескопический погрузчик",
  "buldozer": "Бульдозер",
  "avtovyshka": "Автовышка",
  "samosval": "Самосвал",
  "polivomoechnaya-mashina": "Поливомоечная машина",
  "yamobur": "Ямобур (БКМ)",
  "avtogreider": "Автогрейдер",
  "evakuator": "Эвакуатор",
  "mini-kran": "Мини кран",
  "shalanda-manipulyator": "Шаланда-манипулятор",
  "asfaltoukladchik": "Асфальтоукладчик",
  "truboukladchik": "Трубоукладчик",
  "ekskavator-planirovshchik": "Экскаватор-планировщик (УДС)",
  "dorozhnaya-freza": "Фреза дорожная",
  "podemnik-stroitelnyi": "Подъемник строительный",
  "sochlenennyi-samosval": "Шарнирно-сочленный самосвал (думпер)",
  "bortovoi-avtomobil": "Бортовой автомобиль",
  "dorozhnyi-katok": "Каток",
  "gusenichnyi-kran": "Кран гусеничный",
  "mtz-schyotkoy": "МТЗ с щеткой",
  "betononasos": "Бетононасос",
  "pumik": "Миксер с бетононасосом (пумик)",
  "kompressor": "Компрессор",
  "autobusi": "Автобусы и микроавтобусы",
  "ilosos": "Илосос",
  "metallovoz": "Металловоз",
  "puhtovoz": "Мультилифт/Пухтовоз",
  "diametrBureniya": "Диаметр бурения",
  "kolichestvoOsey": "Количество осей",
  "kolichestvoMolotkov": "Количество молотков",
  "tipPrivoda": "Тип привода",
  "dlinaStrely": " Длина стрелы",
  "minShirinaUkladki": "Мин. ширина укладки",
  "glubinaBureniya": "Глубина бурения",
  "vezdekhod": "Вездеход",
  "obyomKuzova": "Объем кузова",
  "dlinaBorta": "Длина борта",
  "svaekusalka": "Тарелка для обкуски свай",
  "uzkiyKovsh": "Узкий ковш",
  "nalichie-kmu": "Наличие КМУ",
  "maksimalnoeDavlenieNasosa": "Максимальное давление насоса",
  "tip": "Тип",
  "yomkost": "Ёмкость",
  "dlinna-shlanga": "Длина шланга",
  "vyletStrely": "Вылет стрелы",
  "vysotaPodyoma": "Высота подъема",
  "gidromolot": "Гидромолот",
  "ravnorazmernyeKolesa": "Равноразмерные колеса",
  "rykhlitel": "Рыхлитель",
  "nalichieDOPOG": "Наличие ДОПОГ",
  "ves": "Вес",
  "klyk": "Клык",
  "onlyApproved": "Только проверенные собственники",
  "polnoprivodnyi": "Полноприводный",
  "gusek": "Удлинитель (гусек)",
  "obyomKovsha": "Объем ковша",
  "rotator": "Ротатор",
  "obyomSmesitelya": "Объем смесителя",
  "shirinaGusenitsy": "Ширина гусеницы",
  "kovsh": "Ковш",
  "tipKatkaа": "Тип катка",
  "naklonnyiKovsh": "Наклонный ковш",
  "gruzopodemnost": "Грузоподьемность",
  "grejfer": "Грейфер",
  "bezvozdushnyeShiny": "Безвоздушные шины",
  "lyulka": "Люлька",
  "rabochayaVysota": "Рабочая высота",
  "planirovochnyiKovsh": "Планировочный ковш",
  "vysotaPogruzki": "Высота погрузки",
  "obyom": "Объем",
  "kol-vo-posadochnyh-mest": "Кол-во посадочных мест",
  "shirinaFrezirovaniya": "Ширина фрезирования",
  "obyomTsisterny": "Объем цистерны",
  "shetka": "Щетка",
  "maksShirinaUkladki": "Макс. ширина укладки",
  "gruzopodyomnostBorta": "Грузоподъемность борта",
  "vysota": "Высота",
  "gruzopodyomnost_na_strele": "Грузоподьемность на стреле",
  "naznachenie": "Назначение",
  "gruzopodyomnost": "Грузоподъемность",
  "avtonomnyjPodogrevatel": "Автономный подогреватель",
  "gidravlicheskieNozhnitsy": "Гидравлические ножницы",
  "vily": "Вилы",
  "glubina-otkachki": "Глубина откачки",
  "proizvoditelnost-nasosa": "Производительность насоса",
  "konstruktsiyaStrely": "Конструкция стрелы",
  "gruzopodyomnostStrely": "Грузоподъемность стрелы",
  "tarelkiDlyaObkuskiSvai": "Тарелки для обкуски свай",
  "tiagovoeusilenie": "Тяговое усиление",
  "dlinatrosa": "Длина троса",
  "paroproizoditelnost": "Паропроизводительность",
  "parogeneratorType": "Тип генератора",
  "vidtopliva": "Вид топлива",
  "kabelnayalebedka": "Кабельная лебедка",
  "parogenerator": "Парогенератор",
  "vilochnypogruzchik": "Вилочный погрузчик",
  "kanalopromyvochnaya_machina": "Каналопромывочная машина",
  "dlina_shlanga": "Длинна шланга"
}
