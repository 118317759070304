<div class="card-box" [class.selected]="isSelected">
  @if (isSelected) {
    <div class="sign-info">Ожидает подписания исполнителем</div>
  }
  @if (machine) {
    <div class="machines">
      <div class="machine" (click)="showMachine(machine)">
        <div class="machine-info">
          <div class="machine-header">
            <div class="machine-category">{{ machine.categoryName }}</div>
            <span>{{ machine.mainParam }}</span>
          </div>
          <div class="machine-model">{{ machine.model }}, {{ machine.year }}г.</div>
          <div class="distance">
            <div class="distance-icon"><ion-icon name="location-sharp"></ion-icon></div>
            <div class="distance-text">{{ distanceToMachineBase }} км</div>
          </div>
          @if (machine.verificationStatus === "approved") {
            <div class="verification-status">
              <ion-icon class="verified-icon" name="checkmark-circle"></ion-icon>
              Техника подтверждена
            </div>
          }
        </div>
        @if (machine.photo) {
          <div class="image-container">
            @if (machine.verificationStatus === "approved") {
              <ion-icon class="verified-icon" name="checkmark-circle"></ion-icon>
            }
            <div class="machine-photo">
              <img src="{{ machine.photo }}?w=300&h=300" />
            </div>
          </div>
        }
      </div>
    </div>
  }
  @if (!machine) {
    <div class="machines">
      <div class="empty-machine machine">
        <img src="../../../assets/await.svg" />
        <p>Техника будет указана исполнителем при подписании</p>
      </div>
    </div>
  }
  @if (data?.budgetValue) {
    <div class="price-and-payment">
      @if (data.budgetValue) {
        <div class="budget">
          <span class="price">{{ formattedPrice }}</span>
          {{ perUnit }}
        </div>
      }
      @if (data.paymentType) {
        <div>{{ paymentType }}</div>
      }
    </div>
  }
  @if (userPropose?.comment) {
    <div class="entry-comment">
      {{ userPropose?.comment }}
    </div>
  }
  <div class="profile">
    <div class="header-content" (click)="showCompany()">
      <div class="header-info">
        @if (company) {
          <div class="company-name">{{ company.name }}</div>
        }
        <div class="profile-name">{{ user.firstname }} {{ user.lastname }}</div>
      </div>
      <ion-avatar class="profile-avatar">
        @if (user.avatar) {
          <img src="{{ user.avatar }}?w=300&h=300" alt="avatar" />
        }
        @if (!user.avatar) {
          <img src="../../../assets/avatar.svg" />
        }
      </ion-avatar>
    </div>
    <ion-buttons>
      <ion-button class="call-me" fill="outline" (click)="callTo()">
        <ion-icon name="call"></ion-icon>
      </ion-button>
      @if (!isSelected) {
        <ion-button class="select-propose" fill="outline" (click)="selectPropose()">
          Выбрать исполнителем
        </ion-button>
      }
      @if (isSelected) {
        <ion-button fill="outline" class="reject-propose" (click)="rejectPropose()">
          Отменить подпись
        </ion-button>
      }
    </ion-buttons>
  </div>
</div>
